import React, { lazy } from 'react';
import axios from 'axios';
import { BrowserRouter as Router, Routes, Route } from 'react-router-dom';
import 'bootstrap/dist/css/bootstrap.min.css';
import './styles/App.css';

axios.defaults.withCredentials = true; 

const MainLayout = lazy(() => import('./layouts/MainLayout'));
const CmsApp = lazy(() => import('./CmsApp'));
const NotFoundPage = lazy(() => import('./pages/NotFoundPage'));

function App() {
  return (
    <Router>
      <React.Suspense>
        <Routes>
          {/* Main website layout */}
          <Route path="/*" element={<MainLayout />} />

          {/* CMS layout */}
          <Route path="/cms/*" element={<CmsApp />} />

          {/* Catch-all for 404 Not Found */}
          <Route path="*" element={<NotFoundPage />} />
        </Routes>
      </React.Suspense>
    </Router>
  );
}

export default App;
